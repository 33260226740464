import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface Question {
  id: number;
  question: string;
  category: string;
}

type Props = {
  message: string;
  questionOpen: boolean;
  question: Question;
  minutes: number;
  seconds: number;
};

function Questions({
  questionOpen,
  question,
  minutes,
  seconds,
  message,
}: Props) {
  if (!questionOpen) {
    return null;
  }

  return (
    <CardWrap>
      <WideCard>
        {questionOpen && <QuestionWrap>{question.question}</QuestionWrap>}
        {questionOpen && (
          <InfoWrap>
            <div>
              <InfoHead>Tips:</InfoHead>
              <Info>Answer the question out loud.</Info>
              <Info>Speak for between 1-2mins.</Info>
            </div>
            <div>
              <Stopwatch>
                {minutes}:{seconds}
              </Stopwatch>
              {message && <Message>{message}</Message>}
            </div>
          </InfoWrap>
        )}
        <StyledLink to="/sign-up">
          <RoundButton>Get started for free</RoundButton>
        </StyledLink>
      </WideCard>
    </CardWrap>
  );
}

export default Questions;

const InfoHead = styled.div`
  font-size: 1.125em;
  font-weight: 700;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const InfoWrap = styled.div`
  display: grid;
  text-align: left;
  margin-top: 15px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const Info = styled.div`
  margin-top: 7px;
`;

const QuestionWrap = styled.div`
  font-weight: 700;
  font-size: 1.25em;
  text-decoration: underline;
  letter-spacing: 1px;
`;

const CardWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const WideCard = styled.div`
  background: #f6f1f4;
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
`;

const Stopwatch = styled.div`
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
  margin-top: 20px;
`;

const Message = styled.div`
  text-align: center;
  margin-top: 8px;
  font-weight: 700;
  font-size: 1.125em;
`;

const RoundButton = styled.button`
  border-radius: 30px;
  background: black;
  color: white;
  padding: 12px 20px;
  border: none;
  font-weight: 700;
  font-size: 1em;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 45px;

  :hover {
    text-decoration: none;
    color: black;
  }
`;
