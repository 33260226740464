import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { isDesktop } from 'App/helpers/screenSize';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isDesktop ? 400 : '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  height: !isDesktop ? '80vh' : 'unset',
  overflowY: !isDesktop && 'auto',
};

// interface BoxProps {
//   isDesktop: boolean;
// }

// interface ModalProps {
//   isDesktop: boolean;
// }

// type Props = {
//   open: boolean,
//   setOpen: (bool: boolean) => void,
//   content: ReactElement,
// };

export default function BasicModal({ setOpen, open, content }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <StyledModal
        isDesktop={isDesktop}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} isDesktop={isDesktop}>
          {content}
        </Box>
      </StyledModal>
    </div>
  );
}

const StyledModal = styled(Modal)``;
