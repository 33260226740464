import { SignIn } from '@clerk/clerk-react';
import styled from 'styled-components';

export default function SignInPage() {
  return (
    <Wrap>
      <SignIn
        routing="path"
        path="/sign-in"
        afterSignInUrl="/soap"
        signUpUrl="/sign-up"
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
