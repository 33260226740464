export const minus = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    height="14"
    width="14"
    strokeWidth="1.5px"
  >
    <line
      x1="0.5"
      y1="7"
      x2="13.5"
      y2="7"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></line>
  </svg>
);
