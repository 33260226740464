export const questionList = [
  {
    id: 1,
    question: 'What do you wish someone taught you a long time ago?',
    category: 'work',
  },
  {
    id: 2,
    question: 'How do you spend most of your time?',
    category: 'hobbies',
  },
  {
    id: 3,
    question: "What's the worst advice you've ever recieved?",
    category: 'life',
  },
  { id: 4, question: 'Whats your favourite food?', category: 'life' },
  {
    id: 5,
    question: 'What kind of challenges are you facing these days?',
    category: 'life',
  },
  {
    id: 6,
    question:
      'When is the most interesting period on history you can think of?',
    category: 'history',
  },
  {
    id: 7,
    question: 'What book has had the most impact on you?',
    category: 'learning',
  },
  {
    id: 8,
    question: "What's the most incredible natural wonder you've seen?",
    category: 'world',
  },
  {
    id: 9,
    question: 'What are you currently grateful for?',
    category: 'life',
  },
  {
    id: 10,
    question: 'What motivates you?',
    category: 'life',
  },
  {
    id: 11,
    question: 'Would you rather spend time with other people or alone?',
    category: 'life',
  },
  {
    id: 12,
    question: "What's the last goal you achieved?",
    category: 'goals',
  },
  {
    id: 13,
    question: "What's your favourite genre of music?",
    category: 'music',
  },
];
