import styled from 'styled-components';
import { useContext } from 'react';

import { Stat, AnswerLimit } from 'App/types/generalTypes';
import { isDesktop } from 'App/helpers/screenSize';
import { AppContext } from 'AppRoutes';

type Props = {
  step: string;
  stats: Stat | undefined;
  answerLimit: AnswerLimit;
};

function Stats({ step, stats, answerLimit }: Props) {
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  if (step !== 'first' && !isDesktop) {
    return null;
  }

  return (
    <Wrap>
      <StatWrap>
        <StatValue>{stats?.total}</StatValue> <StatLabel>Questions</StatLabel>
      </StatWrap>
      <StatWrap>
        <StatValue>{stats?.totalTime}</StatValue>
        <StatLabel>Total time</StatLabel>
      </StatWrap>
      {!isActive && (
        <StatWrap>
          <StatValue>
            {answerLimit.answers}/{answerLimit.limit}
          </StatValue>
          <StatLabel>Today</StatLabel>
        </StatWrap>
      )}
    </Wrap>
  );
}

export default Stats;

const Wrap = styled.div`
  @media (min-width: 800px) {
    display: flex;
    position: absolute;
    top: 20px;
  }
`;

const StatWrap = styled.div`
  @media (min-width: 800px) {
    margin-right: 40px;
    display: flex;
  }
`;

const StatLabel = styled.div`
  color: grey;
  font-size: 0.75em;

  @media (min-width: 800px) {
    margin-top: 5px;
  }
`;

const StatValue = styled.div`
  font-weight: 700;
  font-size: 1.5em;
  color: #264143;
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 0px;
    font-size: 1em;
    margin-right: 5px;
  }
`;
