import styled from 'styled-components';

import LandingPageComponent from 'App/LandingPage/LandingPageComponent';

export default function ToastMastersPage() {
  const title = (
    <>
      Become <Highlight>awesome</Highlight> at table topics
    </>
  );

  return (
    <LandingPageComponent
      title={title}
      subtitle="Tools to help you master table topics and public speaking."
    />
  );
}

const Highlight = styled.span`
  color: white;
`;
