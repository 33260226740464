import styled from 'styled-components';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Snackbar from '@mui/material/Snackbar';

import { AppContext } from 'AppRoutes';

declare global {
  interface Window {
    Paddle: any;
  }
}

const productId = process.env.REACT_APP_PRODUCT_ID;

export default function UpgradePage() {
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const Paddle = window.Paddle;
  const { user } = useUser();
  const userId = user?.id || '';
  const email = user?.primaryEmailAddress?.emailAddress;
  const userInfo = useContext(AppContext);
  const getUser = userInfo?.getUserInfo;

  // ideally want to use redux to make async call
  function onSuccess() {
    getUser?.();
    navigate('/soap');
  }

  const openCheckout = () => {
    Paddle.Checkout.open({
      product: productId,
      email,
      passthrough: userId,
      closeCallback: onSuccess,
    });
  };

  return (
    <>
      <Wrap>
        <CardWraps>
          <PriceCardWrap>
            <CostCard>
              <CardTitle>Free</CardTitle>
              <Price>$0</Price>
              <ListGroup>
                <ListItem>10 questions/day</ListItem>
                <ListItem>100's of questions available</ListItem>
                <ListItem>Tips to get better</ListItem>
                <ListItem>Stats</ListItem>
                <ListItem>3 stories</ListItem>
              </ListGroup>
              <FlexGrow />
              <Button disabled>Current plan</Button>
            </CostCard>
          </PriceCardWrap>
          <PriceCardWrap>
            <PremiumCard>
              <CardTitle>Premium</CardTitle>
              <Price>
                $8<PriceDuration>/month</PriceDuration>
              </Price>
              <ListGroup>
                <ListItem>Unlimited questions</ListItem>
                <ListItem>100's of questions available</ListItem>
                <ListItem>Tips to get better</ListItem>
                <ListItem>Stats</ListItem>
                <ListItem>Unlimited stories</ListItem>
                <ListItem>Free 7 day trial</ListItem>
                <ListItem>Cancel anytime</ListItem>
              </ListGroup>
              <PremiumButton onClick={openCheckout}>
                Start free trial
              </PremiumButton>
            </PremiumCard>
          </PriceCardWrap>
        </CardWraps>
      </Wrap>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackBarOpen(false)}
        message={'Your upgrade was successful'}
      />
    </>
  );
}

const Wrap = styled.div`
  padding: 0px 60px 150px;
  position: relative;
  background: white;
  height: 100vh;

  @media (max-width: 767px) {
    padding: 60px 20px;
    height: unset;
  }
`;

const CardWraps = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PriceCardWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const CostCard = styled.div`
  background: white;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  max-width: 250px;
  border-radius: 30px;
  border: 1px solid lightgrey;
  position: relative;
  margin-top: 30px;
`;

const PremiumCard = styled.div`
  background: #e97451;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  max-width: 250px;
  border-radius: 30px;
  color: white;
  position: relative;
  margin-top: 30px;
  border: 2px solid black;
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 2em;
  margin-top: 20px;
`;

const PriceDuration = styled.span`
  font-size: 0.875rem;
`;

const ListGroup = styled.ul`
  margin-top: 15px;
  padding-left: 16px;
`;

const ListItem = styled.li`
  margin-top: 8px;
`;

const Button = styled.button`
  background: white;
  color: grey;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  padding: 12px 17px;
  border: none;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
  margin-top: 30px;
`;

const PremiumButton = styled(Button)`
  color: #e97451;

  :hover {
    cursor: pointer;
    transform: translate(0px, -2px);
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;
