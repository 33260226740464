export const arrows = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.125 -0.125 14.25 14.25"
    height="16"
    width="16"
    strokeWidth="1.25"
  >
    <g>
      <path
        d="M.5,7.54,6.65,1.4a.48.48,0,0,1,.7,0L13.5,7.54"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M.5,12.75,6.65,6.6a.5.5,0,0,1,.7,0l6.15,6.15"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
);
