export const bin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.25 -0.25 14.5 14.5"
    height="16"
    width="16"
    strokeWidth="1"
  >
    <g>
      <line
        x1="1"
        y1="3.5"
        x2="13"
        y2="3.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <path
        d="M2.5,3.5h9a0,0,0,0,1,0,0v9a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-9A0,0,0,0,1,2.5,3.5Z"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M4.5,3.5V3a2.5,2.5,0,0,1,5,0v.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <line
        x1="5.5"
        y1="5.5"
        x2="5.5"
        y2="11"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <line
        x1="8.5"
        y1="5.5"
        x2="8.5"
        y2="11"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
    </g>
  </svg>
);
