import styled from 'styled-components';
import { Button } from 'styles/buttons';
import { useContext, useState } from 'react';
import Modal from 'App/Components/Modal';

import { AppContext } from 'AppRoutes';

export default function Account() {
  const { userInfo } = useContext(AppContext) || {};
  const { status, cancelUrl, updateUrl } = userInfo || {};

  const [modalOpen, setModalOpen] = useState(false);

  const cancelled = status === 'deleted';

  const modalContent = () => {
    return (
      <div>
        <ModalTitle>
          Are you sure you want to cancel your subscription?
        </ModalTitle>
        <CancelButton>
          <StyledLink
            href={cancelUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Yes I do
          </StyledLink>
        </CancelButton>
      </div>
    );
  };

  return (
    <>
      <Wrap>
        <Header>
          <div>
            <Title>Your account</Title>
            <Subtitle>Manage your account with Soapboxer</Subtitle>
          </div>
          <ButtonWrap>
            <StyledButton>
              {' '}
              <StyledLink
                href={updateUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Update payment details
              </StyledLink>
            </StyledButton>
          </ButtonWrap>
          {!cancelled && (
            <ButtonWrap>
              <CancelButton onClick={() => setModalOpen(true)}>
                Cancel subscription
              </CancelButton>
            </ButtonWrap>
          )}
        </Header>
      </Wrap>
      <Modal setOpen={setModalOpen} open={modalOpen} content={modalContent()} />
    </>
  );
}

const Wrap = styled.div`
  text-align: left;

  @media (min-width: 800px) {
    padding: 12px 0;
  }
`;

const Header = styled.div`
  @media (max-width: 799px) {
    padding: 20px;
    background: #e97451;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Subtitle = styled.div`
  color: grey;
  margin-top: 8px;
  font-size: 0.875em;
  margin-bottom: 30px;

  @media (max-width: 799px) {
    color: white;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  background: #2d545e;
  @media (max-width: 799px) {
    color: white;
  }
`;

const CancelButton = styled(Button)`
  background: #811331;
`;

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 1.25em;
  margin-bottom: 40px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
`;
