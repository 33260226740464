import styled from 'styled-components';
import Select from 'react-select';
import Snackbar from '@mui/material/Snackbar';

import {
  difficultyOptions,
  DifficultyOption,
  Option,
  typeOptions,
  Question,
  categoryOptions,
} from 'App/Admin/options';

type Props = {
  handleChange: (option: Option, select: string) => void;
  handleDifficultyChange: (option: DifficultyOption) => void;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: Question;
  submit: () => void;
  snackBarOpen: boolean;
  setSnackBarOpen: (isOpen: boolean) => void;
  questions: Question[];
  handleChangeUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitCsv: () => void;
  file: File | undefined;
};

const customStyles = {
  control: (provided: object) => ({
    ...provided,
    borderColor: '#000000',
    background: 'transparent',
    color: 'grey',
    textalign: 'left',
    height: '40px',
    marginTop: '5px',
  }),
};

function AdminComponent({
  handleChange,
  handleDifficultyChange,
  handleInput,
  values,
  submit,
  snackBarOpen,
  setSnackBarOpen,
  questions,
  handleChangeUpload,
  submitCsv,
  file,
}: Props) {
  const disabled = !values.question;

  function renderQuestions() {
    return questions.map((question) => {
      const { question: name, category, id, type, difficulty } = question;
      return (
        <Card key={id}>
          <QuestionTitle>{name}</QuestionTitle>
          {category && (
            <CardItem>
              <strong>Category:</strong> {category}
            </CardItem>
          )}
          {type && (
            <CardItem>
              <strong>Type:</strong> {type}
            </CardItem>
          )}
          {difficulty && (
            <CardItem>
              <strong>Difficulty:</strong> {difficulty}
            </CardItem>
          )}
        </Card>
      );
    });
  }

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackBarOpen(false)}
        message="Great success"
      />
      <Wrap>
        <AddQuestion>
          <AddQuestionWrap>
            <Title>Add question</Title>
            <InputWrap>
              <Input
                placeholder="Question"
                value={values.question}
                onChange={(event) =>
                  handleInput(event as React.ChangeEvent<HTMLInputElement>)
                }
              />
            </InputWrap>
            <InputWrap>
              <Label>Type</Label>
              <Select
                value={{ value: '', label: values.type }}
                styles={customStyles}
                onChange={(option) => handleChange(option as Option, 'type')}
                options={typeOptions}
              />
            </InputWrap>
            <InputWrap>
              <Label>Difficulty</Label>
              <Select
                value={{
                  value: 0,
                  label: values.difficulty > 0 && values.difficulty.toString(),
                }}
                styles={customStyles}
                onChange={(option) =>
                  handleDifficultyChange(option as DifficultyOption)
                }
                options={difficultyOptions}
              />
            </InputWrap>
            <InputWrap>
              <Label>Category</Label>
              <Select
                value={{ value: '', label: values.category }}
                styles={customStyles}
                onChange={(option) =>
                  handleChange(option as Option, 'category')
                }
                options={categoryOptions}
              />
            </InputWrap>
          </AddQuestionWrap>
        </AddQuestion>
        <SubmitButton onClick={submit} disabled={disabled}>
          Submit
        </SubmitButton>
        <Form>
          {file ? (
            <>{file?.name}</>
          ) : (
            <UploadInput
              type={'file'}
              id={'csvFileInput'}
              accept={'.csv'}
              onChange={handleChangeUpload}
            />
          )}

          <SubmitButton onClick={submitCsv}>Import csv</SubmitButton>
        </Form>
        <Title>Questions</Title>
        <CardWrap>{renderQuestions()}</CardWrap>
      </Wrap>
    </>
  );
}

export default AdminComponent;

const Wrap = styled.div`
  padding: 50px 20px;
`;

const AddQuestion = styled.div`
  @media (min-width: 575px) {
    display: flex;
    justify-content: center;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.5em;
  margin-bottom: 40px;
`;

const SubmitButton = styled.button`
  background: #264143;
  color: white;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  width: 180px;
  height: 55px;
  border: none;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 10%);
  margin-top: 40px;
  margin-bottom: 80px;

  ${({ disabled }) => disabled && 'background: grey;'};
`;

const AddQuestionWrap = styled.div``;

const InputWrap = styled.div`
  margin-top: 15px;
  text-align: left;

  @media (min-width: 500px) {
    width: 350px;
  }
`;

const Label = styled.label``;

const Input = styled.input`
  height: 36px;
  border-radius: 5px;
  border: 1px solid black;
  width: 97%;
  background: transparent;
  margin-top: 5px;
  font-size: 1em;
  padding-left: 8px;
`;

const Card = styled.div`
  background: white;
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 10%);
`;

const CardItem = styled.div`
  margin-top: 15px;
`;

const QuestionTitle = styled.div`
  font-weight: 700;
  text-decoration: underline;
`;

const CardWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Form = styled.div``;
const UploadInput = styled.input``;
