import styled from 'styled-components';
import dayjs from 'dayjs';

import { Story } from 'App/types/generalTypes';
import { bin } from '../../styles/icons/bin';
import { pen } from '../../styles/icons/pen';
import { minus } from '../../styles/icons/minus';
import { plus } from '../../styles/icons/plus';

type Props = {
  story: Story;
  setOpenStory: (bool: number | undefined) => void;
  openStory: number | undefined;
  setDeleteModalOpen: (bool: boolean) => void;
  editStory: (story: Story) => void;
};

export default function StoryCard({
  story,
  openStory,
  setOpenStory,
  setDeleteModalOpen,
  editStory,
}: Props) {
  const { title, shortDescription, date, id, description, message } = story;
  const formatted = dayjs(date).format('DD/MM/YYYY');
  const match = openStory === id;
  return (
    <Wrap key={id}>
      <Details>
        <StoryTitle>{title}</StoryTitle>
        <ShortDescription>{shortDescription}</ShortDescription>
        {match && (
          <>
            <Description>{description}</Description>
            <Message>{message}</Message>
          </>
        )}
        <CardWrap>
          <CardDate>{formatted}</CardDate>
        </CardWrap>
      </Details>
      <Actions>
        {!match ? (
          <Icon onClick={() => setOpenStory(id)}>{plus}</Icon>
        ) : (
          <>
            <Icon onClick={() => setOpenStory(-1)}>{minus}</Icon>
          </>
        )}
        {match && (
          <UpdateWrap>
            <Icon onClick={() => editStory(story)}>{pen}</Icon>
            <Icon onClick={() => setDeleteModalOpen(true)}>{bin}</Icon>
          </UpdateWrap>
        )}
      </Actions>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 16px 24px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const StoryTitle = styled.div`
  font-weight: 700;
  font-size: 0.875em;
`;

const ShortDescription = styled.div`
  color: grey;
  font-size: 0.875em;
  margin-top: 4px;
`;

const CardWrap = styled.div`
  display: flex;
`;

const CardDate = styled.div`
  background: lightgrey;
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 4px;
  margin-top: 10px;
`;

const Details = styled.div``;

const Actions = styled.div`
  display: flex;
  position: relative;
`;

const Description = styled.div`
  margin-top: 20px;
  font-size: 0.875em;
`;

const Message = styled.div`
  margin-top: 20px;
  font-size: 0.875em;
  font-weight: 700;
`;

const Icon = styled.div`
  margin-left: 8px;

  :hover {
    cursor: pointer;
  }
`;

const UpdateWrap = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  right: 0;
`;
