import styled from 'styled-components';
import { AnswerLimit } from 'App/types/generalTypes';

interface ButtonProps {
  isDefault: boolean;
}

type Props = {
  step: string;
  resetQuestion: (message: string) => void;
  handleQuestion: () => void;
  setupQuestion: () => void;
  answerLimit: AnswerLimit;
};
export default function Buttons({
  answerLimit,
  step,
  handleQuestion,
  resetQuestion,
  setupQuestion,
}: Props) {
  if (answerLimit.exceeded) {
    return null;
  }

  switch (step) {
    case 'second':
      return (
        <Button isDefault={false} onClick={() => handleQuestion()}>
          Start first question
        </Button>
      );
    case 'third':
      return (
        <Button isDefault={false} onClick={() => resetQuestion('')}>
          Finished
        </Button>
      );
    case 'fourth':
      return (
        <Button isDefault={false} onClick={() => handleQuestion()}>
          Next question
        </Button>
      );
    default:
      return (
        <Button isDefault={true} onClick={() => setupQuestion()}>
          Start practise
        </Button>
      );
  }
}

const Button = styled.button<ButtonProps>`
  background: #f2f6f8;
  color: black;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  padding: 12px 17px;
  border: none;
  font-family: 'Satoshi', sans-serif;

  ${({ disabled }) => disabled && 'background: grey;'};

  :hover {
    cursor: pointer;
  }

  @media (min-width: 800px) {
    background: #e97451;
    color: white;
    ${({ isDefault }) => isDefault && 'margin-top: 0px'};
  }
`;
