export const write = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.125 -0.125 14.25 14.25"
    height="16"
    width="16"
    strokeWidth="1.25"
  >
    <g>
      <path
        d="M8,13.5H1.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H9"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.5,3.5,12,.5l1.5,3V12a1.5,1.5,0,0,1-3,0Z"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <line
        x1="10.5"
        y1="9.5"
        x2="13.5"
        y2="9.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <line
        x1="3.5"
        y1="0.5"
        x2="3.5"
        y2="13.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <line
        x1="6"
        y1="4"
        x2="8"
        y2="4"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
    </g>
  </svg>
);
