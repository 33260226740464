import { useState, useEffect, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MembershipBenefits from 'App/LandingPage/MembershipBenefits';
import wavy from 'styles/svgs/wavy.svg';
import bubbles from 'styles/svgs/bubbles.svg';
import bluelines from 'styles/svgs/bluelines.svg';
import { useStopwatch } from 'react-timer-hook';
import HeroFooter from 'App/LandingPage/HeroFooter';
import Questions from 'App/LandingPage/Questions';
import { questionList } from 'App/LandingPage/questionList';
import Footer from 'App/LandingPage/Footer';
import marketing from '../../styles/marketing/marketing.png';
import marketing1 from '../../styles/marketing/marketing1.png';
import marketing2 from '../../styles/marketing/marketing2.png';
import { isDesktop } from 'App/helpers/screenSize';

interface Question {
  id: number;
  question: string;
  category: string;
}

type Props = {
  title: ReactNode;
  subtitle: string;
};

function LandingPage({ title, subtitle }: Props) {
  const [step, setStep] = useState<string>('first');
  const [question, setQuestion] = useState<Question>({
    id: 0,
    question: '',
    category: '',
  });
  const [message, setMessage] = useState<string>('');
  const [completedQuestions, setCompletedQuestions] = useState<Array<Number>>(
    []
  );
  const questionOpen = step === 'second' || step === 'third';

  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const timeOver = minutes === 2 && seconds > 30;

  const resetQuestion = useCallback(
    (message: string) => {
      pause();
      setStep('second');
      setCompletedQuestions([...completedQuestions, question.id]);

      // set different messages based on time
      if (message) {
        setMessage(message);
        return;
      }

      if (minutes === 0) {
        setMessage('Great work, try and reach a minute next time.');
        return;
      }

      setMessage('Great work, have another go.');
    },
    [minutes, pause, completedQuestions, question]
  );

  useEffect(
    function maxTimeExceeded() {
      if (timeOver && step === 'third') {
        resetQuestion('Ahhh heck, you went over the max time. Try again.');
      }
    },
    [timeOver, resetQuestion, step]
  );

  function handleQuestion() {
    setMessage('');

    const filteredQuestions = questionList.filter((question) => {
      return !completedQuestions.find((completed) => completed === question.id);
    });

    if (filteredQuestions.length === 0) {
      setMessage("You've been through all the questions, good work!");
      return;
    }

    reset();
    const question = filteredQuestions[Math.floor(Math.random() * 3)];
    setQuestion(question);

    setStep('third');
  }

  const renderButton = () => {
    if (step === 'first') {
      return (
        <Button onClick={() => setStep('second')}>Practise out loud</Button>
      );
    }

    if (step === 'second') {
      return <Button onClick={() => handleQuestion()}>Start</Button>;
    }

    if (step === 'third') {
      return <Button onClick={() => resetQuestion('')}>Done</Button>;
    }
  };

  return (
    <>
      <Wrap>
        <BluelinesWrap>
          <Bluelines src={bluelines} />
        </BluelinesWrap>
        <BubblesWrap>
          <Bubbles src={bubbles} alt="small circles" />
        </BubblesWrap>
        <Header>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Header>
        {renderButton()}
        {questionOpen && (
          <SquiggleWrap>
            <Squiggle src={wavy} alt="wavy line" />
          </SquiggleWrap>
        )}
        <Questions
          questionOpen={questionOpen}
          question={question}
          minutes={minutes}
          seconds={seconds}
          message={message}
        />
      </Wrap>
      <Section>
        <BigSubtitle>Grow your confidence</BigSubtitle>

        <TextWrap>
          <LargeText>
            Just a few minutes of answering questions out loud each day will
            make you feel and appear much more confident.
          </LargeText>
        </TextWrap>
        <BigSubtitle>Be better prepared</BigSubtitle>
        <TextWrap>
          <LargeText>
            You <strong>can</strong> prepare for off the cuff speaking, by
            improving your quick thinking and by having a few tools up your
            sleeve.
          </LargeText>
        </TextWrap>
        <BigSubtitle>Reduce your nerves</BigSubtitle>
        <TextWrap>
          <LargeText>
            By getting used to being put on the spot you'll become more
            confident in yourself and create better responses.
          </LargeText>
        </TextWrap>
      </Section>
      <MiddleSection>
        <SplitSection>
          <TextSection>
            <SectionTitle>Practise your public speaking in safety</SectionTitle>
            <Text>
              Soapboxer makes it easy for you to practise your off the cuff
              speaking at home.
            </Text>
            <Text>
              There are 100's of varied questions to challenge you, to improve
              your quick thinking and to help you grow your speaking skills.
            </Text>
            <Text>We even give you a coaching tip with each question.</Text>
          </TextSection>
          <Image src={marketing} alt="" />
        </SplitSection>
        <SplitSection>
          {isDesktop ? (
            <>
              {' '}
              <Image src={marketing2} alt="" />
              <TextSection>
                <SectionTitle>Turn your memories into stories</SectionTitle>
                <Text>
                  Great public speakers have a story to tell. Now you can too.
                </Text>
                <Text>
                  With Soapboxer you can create your own collection of stories
                  so you'll always have a story to hand.
                </Text>
                <Text>
                  Focus on the key points of the memory such as how you felt at
                  the time and the core message of the story.
                </Text>
              </TextSection>
            </>
          ) : (
            <>
              <TextSection>
                <SectionTitle>Turn your memories into stories</SectionTitle>
                <Text>
                  Great public speakers have a story to tell. Now you can too.
                </Text>
                <Text>
                  With Soapboxer you can create your own collection of stories
                  so you'll always have a story to hand.
                </Text>
                <Text>
                  Focus on the key points of the memory such as how you felt at
                  the time and the core message of the story.
                </Text>
              </TextSection>{' '}
              <Image src={marketing2} alt="" />
            </>
          )}
        </SplitSection>
        <SplitSection>
          <TextSection>
            <SectionTitle>
              Make your personal stories your superpower
            </SectionTitle>
            <Text>
              Public speaking and communication is often about telling a good
              story.
            </Text>
            <Text>
              Soapboxer makes it easy to centralise, view and edit your stories.
            </Text>
            <Text>
              When you write down and collect your stories in one place, it
              makes it easy to remember them for public speaking or just day to
              day conversations.
            </Text>

            <StyledLink to="/sign-up">
              <RoundButton>Get started for free</RoundButton>
            </StyledLink>
          </TextSection>
          <Image src={marketing1} alt="" />
        </SplitSection>
      </MiddleSection>
      <MembershipBenefits />
      <PriceWrap>
        <CardWraps>
          <PriceCardWrap>
            <CostCard>
              <CardTitle>Free</CardTitle>
              <Price>$0</Price>
              <ListGroup>
                <ListItem>10 questions/day</ListItem>
                <ListItem>100's of questions available</ListItem>
                <ListItem>Tips to get better</ListItem>
                <ListItem>Stats</ListItem>
                <ListItem>Story builder - trial</ListItem>
              </ListGroup>
              <FlexGrow />
              <StyledLink to="/sign-up">
                <PriceButton>Start for free</PriceButton>
              </StyledLink>
            </CostCard>
          </PriceCardWrap>
          <PriceCardWrap>
            <PremiumCard>
              <CardTitle>Premium</CardTitle>
              <Price>
                $8<PriceDuration>/month</PriceDuration>
              </Price>
              <ListGroup>
                <ListItem>Unlimited questions/day</ListItem>
                <ListItem>100's of questions available</ListItem>
                <ListItem>Tips to get better</ListItem>
                <ListItem>Stats</ListItem>
                <ListItem>Story builder - unlimited</ListItem>
                <ListItem>Free 7 day trial</ListItem>
                <ListItem>Cancel anytime</ListItem>
              </ListGroup>
              <StyledLink to="/sign-up">
                <PremiumButton>Upgrade in app</PremiumButton>
              </StyledLink>
            </PremiumCard>
          </PriceCardWrap>
        </CardWraps>
      </PriceWrap>
      <HeroFooter />
      <Footer />
    </>
  );
}

export default LandingPage;

const Section = styled.div`
  background: white;
  padding: 80px 100px 100px;
  border-radius: 100px 100px 0 0;

  @media (max-width: 767px) {
    padding: 60px 20px;
  }
`;

const MiddleSection = styled.div`
  background: #f6f1f4;
  padding: 80px 100px 100px;

  @media (max-width: 767px) {
    padding: 60px 20px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const LargeText = styled.div`
  font-size: 1.125em;
  margin-bottom: 50px;
  letter-spacing: 0.25px;
  max-width: 600px;
`;

const Wrap = styled.div`
  padding: 120px 60px 100px;
  background: #e97451;

  @media (max-width: 767px) {
    padding: 90px 20px;
  }
`;

const Header = styled.div`
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 700px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 4.25em;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    font-size: 3.5em;
  }
`;

const Subtitle = styled.div`
  font-size: 1.5em;
  padding-bottom: 60px;
`;

const Button = styled.button`
  background: white;
  color: black;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  width: 211px;
  height: 55px;
  border: none;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
  font-family: 'Satoshi', sans-serif;

  :hover {
    cursor: pointer;
  }
`;

const Squiggle = styled.img`
  height: 60px;
`;

const SquiggleWrap = styled.div`
  position: absolute;
  right: 50vw;
`;

const Bubbles = styled.img`
  height: 75px;

  @media (min-width: 768px) {
    height: 120px;
  }
`;

const BubblesWrap = styled.div`
  position: absolute;
  right: 6vw;
  top: 90px;

  @media (max-width: 767px) {
    top: 80px;
  }
`;

const Bluelines = styled.img`
  height: 75px;

  @media (min-width: 768px) {
    height: 120px;
  }
`;

const BluelinesWrap = styled.div`
  position: absolute;
  left: -3vw;
  top: 120px;

  @media (max-width: 767px) {
    left: -7vw;
    top: 80px;
  }
`;

const BigSubtitle = styled.div`
  font-weight: 700;
  font-size: 2.5em;
  padding-bottom: 40px;
`;

const SplitSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 120px;
  grid-column-gap: 85px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 40px;
    margin-bottom: 40px;
  }
`;

const TextSection = styled.div`
  margin: auto;
  max-width: 460px;
`;

const Text = styled.div`
  font-size: 1.125em;
  margin-bottom: 15px;
  letter-spacing: 0.25px;
  max-width: 460px;
  text-align: left;
  line-height: 26px;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 2em;
  padding-bottom: 40px;
  text-align: left;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin: auto;
`;

const RoundButton = styled.button`
  border-radius: 30px;
  background: black;
  color: white;
  padding: 12px 20px;
  border: none;
  font-weight: 700;
  font-size: 1em;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  margin-top: 30px;

  :hover {
    text-decoration: none;
    color: black;
  }
`;

const PriceWrap = styled.div`
  padding: 0px 60px 150px;
  position: relative;
  background: white;

  @media (max-width: 767px) {
    padding: 60px 20px;
    height: unset;
  }
`;

const CardWraps = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PriceCardWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const CostCard = styled.div`
  background: white;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  max-width: 250px;
  border-radius: 30px;
  border: 1px solid lightgrey;
  position: relative;
`;

const PremiumCard = styled.div`
  background: #e97451;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  max-width: 250px;
  border-radius: 30px;
  color: white;
  position: relative;
  border: 2px solid black;
  margin-left: 40px;

  @media (max-width: 631px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 2em;
  margin-top: 20px;
`;

const PriceDuration = styled.span`
  font-size: 0.875rem;
`;

const ListGroup = styled.ul`
  margin-top: 15px;
  padding-left: 16px;
`;

const ListItem = styled.li`
  margin-top: 8px;
`;

const PriceButton = styled(Button)`
  background: black;
  color: white;

  :hover {
    cursor: pointer;
    transform: translate(0px, -2px);
  }
`;

const PremiumButton = styled(Button)`
  color: #e97451;

  :hover {
    cursor: pointer;
    transform: translate(0px, -2px);
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;
