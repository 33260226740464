import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { useUser } from '@clerk/clerk-react';
import { Answer, Story, Question, Tip } from 'App/types/generalTypes';

const base = process.env.REACT_APP_BASE_URL;

type Methods = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

export default function useRequest() {
  const { getToken  } = useAuth();
  const { user } = useUser();
  const userId = user?.id || '';

  async function fetch(type: Methods, url: string) {
    const config = {
      headers: { Authorization: `Bearer ${await getToken()}` },
    };

    const response = await axios[type](`${base}${url}`, config);
    return response;
  }

  async function request(type: Methods, url: string, data: any) {
    const config = {
      headers: { Authorization: `Bearer ${await getToken()}` },
    };

    const response = await axios[type](`${base}${url}`, data, config);
    return response;
  }

  const getStats = async (userId: string) => {
    return await fetch('get', `users/${userId}`);
  };

  const getTips = async () => {
    return await fetch('get', `tips`);
  };

  const getUser = async (userId: string) => {
    return await fetch('get', `users/user/${userId}`);
  };

  const getRandomTip = async () => {
    return await fetch('get', `tips/random`);
  };

  const getAnswerLimit = async (id: string) => {
    return await fetch('get', `answers/today/${id}`);
  };

  const getQuestion = async (id: string) => {
    return await fetch('get', `questions/${id}`);
  };

  const postAnswer = async (answer: Answer ) => {
    return await request('post', `answers`, answer);
  };

  const getStories = async (id: string) => {
    return await fetch('get', `stories/${id}`);
  };

  const postStory = async (story: Story) => {
    return await request('post', `stories`, story);
  };

  const updateStory = async (story: Story) => {
    return await request('put', `stories`, story);
  };

  const removeStory = async (id: number) => {
    return await fetch('delete', `stories/${userId}/${id}`);
  };

  const getQuestions = async () => {
    return await fetch('get', `questions`);
  };

  const postQuestion = async (question: Question) => {
    return await request('post', `questions`, question);
  };

  const postQuestionsCsv = async (csv: any) => {
    return await request('post', `questions/csv`, csv);
  };

  const postTip = async (tip: Tip) => {
    return await request('post', `tips`, tip);
  };

  const postTipCsv = async (csv: any) => {
    return await request('post', `tips/csv`, csv);
  };

  return {
    getStats,
    getTips,
    getRandomTip,
    getAnswerLimit,
    getQuestion,
    postAnswer,
    getStories,
    postStory,
    updateStory,
    removeStory,
    getQuestions,
    postQuestion,
    postQuestionsCsv,
    postTip,
    postTipCsv,
    getUser,
  };
}
