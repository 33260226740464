import { useEffect } from 'react';
import useRequest from 'App/hooks/useRequest';
import { useUser } from '@clerk/clerk-react';
import dayjs from 'dayjs';

interface UserInfo {
  updateUrl: string;
  cancelUrl: string;
  isActive: boolean;
  status: string;
}

const defaultUserInfo = {
  updateUrl: '',
  cancelUrl: '',
  isActive: false,
  status: '',
};

export default function useFetchUser(
  setUserInfo: (userInfo: UserInfo) => void
) {
  const user = useUser();
  const userId = user?.user?.id;
  const isAdmin = user?.user?.publicMetadata?.admin;
  const { getUser } = useRequest();
  const today = dayjs();

  useEffect(() => {
    async function fetchUserStatus() {
      if (userId) {
        const status = await getUser(userId);

        const isWithinDate =
          status.data.endDate && today.isBefore(dayjs(status.data.endDate));

        if (
          status.data.status === 'active' ||
          status.data.status === 'trialing' ||
          isWithinDate ||
          isAdmin
        ) {
          setUserInfo({
            isActive: true,
            updateUrl: status.data.updateUrl,
            cancelUrl: status.data.cancelUrl,
            status: status.data.status,
          });
        } else {
          setUserInfo({ ...defaultUserInfo, status: status.data.status });
        }
      }
    }
    userId && fetchUserStatus();
  }, [userId]);
}
