export const difficultyOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

export type DifficultyOption = { label: string; value: number };

export const typeOptions = [
  { value: 'regular', label: 'Regular' },
  { value: 'scenario', label: 'Scenario' },
];

export const categoryOptions = [
  { value: 'life', label: 'Life' },
  { value: 'personal', label: 'Personal' },
  { value: 'music', label: 'Music' },
  { value: 'health', label: 'Health' },
  { value: 'food', label: 'Food' },
  { value: 'culture', label: 'Culture' },
  { value: 'self development', label: 'Self Development' },
  { value: 'sport', label: 'Sport' },
  { value: 'other', label: 'Other' },
];

// toastmaster
// interview
export const tipTypes = [{ value: 'general', label: 'General' }];

export type Option = { label: string; value: string };

export type Question = {
  id?: number;
  question: string;
  type: string;
  difficulty: number;
  category: string;
};

export type Answer = {
  userId: string | undefined;
  questionId: number;
  time: number;
};

export type Tip = {
  id?: number;
  tip: string;
  type: string;
};
