import { useState, useEffect, useCallback } from 'react';

import AdminComponent from 'App/Admin/AdminComponent';
import { DifficultyOption, Option, Question } from 'App/Admin/options';
// @ts-ignore
import { parse, ParseResult } from 'papaparse';
import useRequest from 'App/hooks/useRequest';

function AdminContainer() {
  const defaultValues = {
    question: '',
    type: '',
    difficulty: 0,
    category: '',
  };
  const [values, setValues] = useState<Question>(defaultValues);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [file, setFile] = useState<File>();
  const { getQuestions, postQuestion, postQuestionsCsv } = useRequest();

  const fetchQuestions = useCallback(async () => {
    const gotQuestions = await getQuestions();
    const { data } = gotQuestions;
    if (data && data.length > 0) {
      setQuestions(data);
    }
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  function handleChange(option: Option, select: string) {
    setValues({ ...values, [select]: option.value });
  }

  function handleDifficultyChange(option: DifficultyOption) {
    setValues({ ...values, difficulty: option.value });
  }

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    setValues({ ...values, question: event.target.value });
  }

  function handleChangeUpload(event: React.ChangeEvent<HTMLInputElement>) {
    setFile(event.target.files?.[0]);
  }

  async function submitCsv() {
    console.log(file);
    // const formData = new FormData();

    if (file) {
      parse(file, {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async function (
          results: ParseResult<Record<string, unknown>>
        ) {
          const result = await postQuestionsCsv(results.data);
          const { status } = result;

          if (status === 201) {
            setSnackBarOpen(true);
            setFile(undefined);
          }
        },
      });
    }
  }

  const submit = async () => {
    const postedQuestion = await postQuestion(values);
    const { status, data } = postedQuestion;

    if (status === 201) {
      setValues(defaultValues);
      setSnackBarOpen(true);
      setQuestions([...questions, data]);
    }
  };

  return (
    <AdminComponent
      handleChange={handleChange}
      handleDifficultyChange={handleDifficultyChange}
      handleInput={handleInput}
      values={values}
      submit={submit}
      snackBarOpen={snackBarOpen}
      setSnackBarOpen={setSnackBarOpen}
      questions={questions}
      handleChangeUpload={handleChangeUpload}
      submitCsv={submitCsv}
      file={file}
    />
  );
}

export default AdminContainer;
