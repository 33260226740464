import { ReactElement } from 'react';
import Nav from 'App/Components/Nav';

type Props = {
  Component: ReactElement;
};

export default function SignedOutComponent({ Component }: Props) {
  return (
    <>
      <Nav />
      {Component}
    </>
  );
}
