import { SignUp } from '@clerk/clerk-react';
import styled from 'styled-components';

export default function SignUpPage() {
  return (
    <Wrap>
      <SignUp
        routing="path"
        path="/sign-up"
        signInUrl="/sign-in"
        afterSignUpUrl="/soap"
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
