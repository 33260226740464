import styled from 'styled-components';

export const Button = styled.button`
  background: #f2f6f8;
  color: #f6f1f4;
  font-weight: 700;
  font-size: 1em;
  border-radius: 5px;
  padding: 10px 16px;
  border: none;

  ${({ disabled }) => disabled && 'background: grey;'};

  :hover {
    cursor: pointer;
  }

  @media (min-width: 800px) {
    background: #e97451;
  }
`;
