export const getColour = (minutes: number) => {
  if (minutes >= 2) {
    return '#EE4B2B';
  }

  if (minutes >= 1) {
    return '#097969';
  }
  return 'black';
};
