import './App.css';
import { ClerkProvider } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import AppRoutes from 'AppRoutes';
import { store } from 'App/store';
import { Provider } from 'react-redux';

const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <ClerkProvider frontendApi={frontendApi} navigate={(to) => navigate(to)}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </ClerkProvider>
    </div>
  );
}

export default App;
