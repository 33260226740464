import { useContext } from 'react';

import Stats from 'App/Home/Stats';
import { Stat, Tip, AnswerLimit, Question } from 'App/types/generalTypes';
import Buttons from 'App/Home/Buttons';
import {
  ContentWrap,
  InnerWrap,
  QuestionWrap,
  Stopwatch,
  Message,
  Restart,
  TipWrap,
  TipText,
  TipTitle,
} from 'App/Home/HomeComponent';
import { AppContext } from 'AppRoutes';
import LimitMessage from 'App/Home/LimitMessage';
import { getColour } from 'App/helpers/colour';

type Props = {
  minutes: number;
  seconds: number;
  step: string;
  message: string;
  resetQuestion: (message: string) => void;
  handleQuestion: () => void;
  currentQuestion: Question;
  setupQuestion: () => void;
  restart: () => void;
  stats: Stat | undefined;
  tip: Tip;
  answerLimit: AnswerLimit;
  showQuestion: boolean;
  showRestart: boolean;
  showTimer: boolean;
  selectCategory: (category: string) => void;
  categories: string[];
};

export default function DesktopHome({
  answerLimit,
  step,
  stats,
  handleQuestion,
  resetQuestion,
  setupQuestion,
  showQuestion,
  currentQuestion,
  message,
  showRestart,
  restart,
  minutes,
  seconds,
  showTimer,
  tip,
  selectCategory,
  categories,
}: Props) {
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  const interviewSelected = categories.includes('interview');
  const travelSelected = categories.includes('travel');

  return (
    <>
      <Stats step={step} stats={stats} answerLimit={answerLimit} />
      {!answerLimit.exceeded || isActive ? (
        <>
          <ContentWrap>
            <InnerWrap>
              {/* <div className="mb-8">
                <span
                  className={`inline-flex items-center rounded-full bg-secondary-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 mr-1 hover:cursor-pointer ${
                    interviewSelected && 'border-2 border-black'
                  }}`}
                  onClick={() => selectCategory('interview')}
                >
                  Interview
                </span>
                <span
                  className={`inline-flex items-center rounded-full bg-primary-100 px-1.5 py-0.5 text-xs font-medium text-red-700 ${
                    travelSelected && 'border-2 border-black'
                  }`}
                  onClick={() => selectCategory('travel')}
                >
                  Travel
                </span>
              </div> */}
              <Buttons
                answerLimit={answerLimit}
                step={step}
                handleQuestion={handleQuestion}
                resetQuestion={resetQuestion}
                setupQuestion={setupQuestion}
              />
              {showQuestion && (
                <QuestionWrap>{currentQuestion.question}</QuestionWrap>
              )}
              {message && <Message>{message}</Message>}
              {showRestart && <Restart onClick={restart}>Restart</Restart>}
              {showTimer && (
                <Stopwatch colour={getColour(minutes)}>
                  {minutes}:{seconds}
                </Stopwatch>
              )}
              {showTimer && !answerLimit.exceeded && (
                <TipWrap>
                  <TipText>{tip?.tip}</TipText>
                  <TipTitle>Coach</TipTitle>
                </TipWrap>
              )}{' '}
            </InnerWrap>
          </ContentWrap>
        </>
      ) : (
        <LimitMessage />
      )}
    </>
  );
}
