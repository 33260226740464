export const speaker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.25 -0.25 14.5 14.5"
    height="16"
    width="16"
    strokeWidth="1.25"
  >
    <g>
      <path
        d="M12.84,12.07,1.17,8A1,1,0,0,1,.5,7.09V6a1,1,0,0,1,.67-.94L12.84,1a.5.5,0,0,1,.66.47V11.6A.5.5,0,0,1,12.84,12.07Z"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M8.48,10.57A2.75,2.75,0,0,1,3,10.25V8.66"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
);
