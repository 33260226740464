export const wrench = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    height="16"
    width="16"
  >
    <path
      d="M13.43,3.59a.76.76,0,0,0-.35-.51l-2,2a1,1,0,0,1-1.44,0L8.88,4.4A1,1,0,0,1,8.88,3l2-2A.76.76,0,0,0,10.4.57,3.8,3.8,0,0,0,6.26,6L.8,11.41a1,1,0,0,0,0,1.43l.36.36a1,1,0,0,0,1.43,0L8.05,7.75a3.81,3.81,0,0,0,5.38-4.16Z"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
