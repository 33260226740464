import styled from 'styled-components';

import Modal from 'App/Components/Modal';
import { Button } from 'styles/buttons';

type Props = {
  setOpen: (bool: boolean) => void;
  open: boolean;
  deleteStory: () => void;
};

export default function DeleteModal({ setOpen, open, deleteStory }: Props) {
  return (
    <Modal
      setOpen={setOpen}
      open={open}
      content={
        <div>
          <Title>Delete Story</Title>
          <Subtitle>Are you sure you want to delete this story?</Subtitle>
          <ButtonWrap>
            <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
            <SubmitButton onClick={deleteStory}>Delete</SubmitButton>
          </ButtonWrap>
        </div>
      }
    />
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Subtitle = styled.div`
  color: grey;
  margin-top: 8px;
  margin-bottom: 30px;
  font-size: 0.875em;
  line-height: 20px;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  color: white;

  ${({ disabled }) => disabled && 'background:grey'};
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
  background: transparent;
  border: 1px solid black;
  color: black;
`;
