import { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import { UserButton, useUser } from '@clerk/clerk-react';
import { isDesktop } from 'App/helpers/screenSize';
import mainlogo from '../../styles/mainlogo.png';
import { speaker } from '../../styles/icons/speaker.js';
import { book } from '../../styles/icons/book.js';
import { love } from '../../styles/icons/love.js';
import { arrows } from '../../styles/icons/arrows.js';
import { write } from '../../styles/icons/write.js';
import { wrench } from '../../styles/icons/wrench.js';
import { AppContext } from 'AppRoutes';
import { settings } from '../../styles/icons/settings';

export default function Nav() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const user = useUser();
  const { isSignedIn } = user;
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  if (isSignedIn && isDesktop) {
    return null;
  }

  const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: string) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <StyledLink to="/soap">
            <IconWrap>{speaker}</IconWrap>
            <NavText>The soapbox</NavText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/stories">
            <IconWrap>{book}</IconWrap>
            <NavText>Stories</NavText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://soapboxer.canny.io/feedback"
          >
            <IconWrap>{write}</IconWrap>
            <NavText>Send feedback</NavText>
          </ExternalLink>
        </ListItem>
        {/* <ListItem>
          {' '}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://tally.so/r/nWOv2a"
          >
            <IconWrap>{love}</IconWrap>
            <NavText>Share testimonial</NavText>
          </ExternalLink>
        </ListItem> */}
        {!isActive && (
          <ListItem>
            <StyledLink to="/upgrade">
              <IconWrap>{arrows}</IconWrap>
              <NavText>Upgrade</NavText>
            </StyledLink>
          </ListItem>
        )}
        <ListItem>
          <StyledLink to="/guide">
            <IconWrap>{wrench}</IconWrap>
            <NavText>Guide</NavText>
          </StyledLink>
        </ListItem>
        {isActive && (
          <ListItem>
            <StyledLink to="/account">
              <IconWrap>{settings}</IconWrap>
              <NavText>Account</NavText>
            </StyledLink>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <Fragment key="right">
        <Header>
          <MenuWrap>
            <UserButton afterSignOutUrl="/" />
          </MenuWrap>
          <Link to="/soap">
            <Logo>
              <Image src={mainlogo} alt="" />
            </Logo>
          </Link>
          <StyledMenuIcon onClick={toggleDrawer('right', true)} />
        </Header>
        <SwipeableDrawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </Fragment>
    </div>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: -1px;
  background: #e97451;
`;

const Logo = styled.div`
  margin-top: 7px;
`;

const Image = styled.img`
  @media (max-width: 767px) {
    width: 130px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  font-size: 0.875em;

  :hover {
    text-decoration: none;
    color: black;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: black;
  display: flex;
  font-size: 0.875em;

  :hover {
    text-decoration: none;
    color: black;
  }
`;

const NavText = styled.div`
  font-weight: 700;
  margin-left: 10px;
  margin-top: 4px;
`;

const MenuWrap = styled.div`
  display: flex;
`;

const StyledMenuIcon = styled(MenuIcon)`
  margin: 6px 0 0 0;
`;

const IconWrap = styled.div`
  margin-top: 4px;
`;
