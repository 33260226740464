import { useState, useEffect, useContext } from 'react';
import StoryComponent from 'App/Story/StoryComponent';
import dayjs, { type Dayjs } from 'dayjs';
import { Story } from 'App/types/generalTypes';
import Snackbar from '@mui/material/Snackbar';
import { useUser } from '@clerk/clerk-react';
import useRequest from 'App/hooks/useRequest';
import { AppContext } from 'AppRoutes';

const defaultValues = {
  title: '',
  shortDescription: '',
  description: '',
  date: '',
  message: '',
  userId: '',
  id: -1,
};

export default function StoryContainer() {
  const today = dayjs(new Date());

  const [date, setDate] = useState<Dayjs>(today);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [story, setStory] = useState<Story>(defaultValues);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');

  const [stories, setStories] = useState<Story[]>([]);
  const [openStory, setOpenStory] = useState<number | undefined>(-1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { getStories, postStory, updateStory, removeStory } = useRequest();
  const { user } = useUser();

  const userId = user?.id;
  const disableSubmit = !story.title || !story.shortDescription || !date;
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  const enabled = isActive || stories.length <= 2;

  useEffect(() => {
    async function fetchStories() {
      if (userId) {
        const stories = await getStories(userId);
        setStories(stories.data);
      }
    }
    fetchStories();
  }, [userId, story]);

  function handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    type: string
  ) {
    const { target } = event;
    const { value } = target;

    setStory({ ...story, [type]: value });
  }

  async function submitStory() {
    if (isEditing) {
      story.date = dayjs(date).format();
      story.userId = userId;

      const updatedStory = await updateStory(story);
      const { status, data } = updatedStory;

      if (status === 201) {
        setStory(defaultValues);
        setDate(today);
        setIsEditing(false);
        setSnackBarMessage('Story successfully updated');
        setSnackBarOpen(true);
        setOpen(false);
      }
    } else {
      delete story.id;
      story.date = dayjs(date).format();
      story.userId = userId;

      const postedStory = await postStory(story);
      const { status, data } = postedStory;

      if (status === 201) {
        setStory(defaultValues);
        setDate(today);
        setSnackBarMessage('Story successfully added');
        setSnackBarOpen(true);
        setOpen(false);
      }
    }
  }

  async function deleteStory() {
    const removed = await removeStory(openStory!);
    const { status, data } = removed;

    if (status === 200) {
      const filtered = stories.filter((story) => story.id !== openStory);
      setStories(filtered);
      setDeleteModalOpen(false);
      setSnackBarMessage('Story successfully removed');
      setSnackBarOpen(true);
    }
  }

  function editStory(story: Story) {
    setIsEditing(true);
    setDate(dayjs(story.date));
    setStory(story);
    setOpen(true);
  }

  function openAddModal() {
    setIsEditing(false);
    setStory(defaultValues);
    setDate(today);
    setOpen(true);
  }

  return (
    <>
      <StoryComponent
        date={date}
        setDate={setDate}
        handleChange={handleChange}
        submitStory={submitStory}
        story={story}
        setOpen={setOpen}
        open={open}
        disableSubmit={disableSubmit}
        stories={stories}
        deleteStory={deleteStory}
        openStory={openStory}
        setOpenStory={setOpenStory}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        editStory={editStory}
        isEditing={isEditing}
        openAddModal={openAddModal}
        enabled={enabled}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
      />
    </>
  );
}
