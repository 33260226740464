import styled from 'styled-components';
import Footer from 'App/LandingPage/Footer';
import dash from '../../styles/guide/dash.png';
import dash2 from '../../styles/guide/dash2.png';
import dashquestion from '../../styles/guide/dashquestion.png';
import storiesdash from '../../styles/guide/storiesdash.png';
import editstory from '../../styles/guide/editstory.png';
import editstories from '../../styles/guide/editstories.png';
import addstorymodal from '../../styles/guide/addstorymodal.png';

export default function GuidePage() {
  return (
    <>
      <Header>
        <Title>Soapboxer Guide</Title>
        <Subtitle>
          Helping you to get started and get the most out of soapboxer.
        </Subtitle>
      </Header>
      <Wrap>
        <Content>
          <Text>
            The main goals of soapboxer are to help you improve your public
            speaking, to enhance your storytelling and to increase your
            confidence.
          </Text>
          <Subheader>Answer questions</Subheader>
          <Text>
            A core feature of Soapboxer is questions. Answering questions out
            loud can help to improve your off the cuff speaking, your quick
            thinking and your in the moment story telling
          </Text>
          <Text>
            Simply click the 'start practise' button on the home dashboard
          </Text>
          <Image alt="" src={dash} />
          <Text>
            This takes you to a holding page where you'll see the stopwatch
            counter at 0 and a coaching tip at the bottom.
          </Text>
          <Image alt="" src={dash2} />
          <Text>When you're ready to start, click the 'start' button.</Text>
          <Image alt="" src={dashquestion} />
          <Text>
            The question to answer will show and the stopwatch will start. The
            aim is to speak for atleast a minute and no longer that 2 mins 30
            seconds. The stopwatch will stop if you go over 2 mins 30 seconds.
          </Text>
          <Text>
            When you've finished your response, click the 'Done' button.
          </Text>
          <Subheader>Adding stories</Subheader>
          <Text>
            A great way to be able to recall memories (for conversations, public
            speaking or just fun) from your past is to turn those memories into
            stories . And a good way to remember these stories is to have then
            written down.
          </Text>
          <Text>With Soapboxer premium you can save your best stories.</Text>
          <Text>You can view your stories on the stories dashboard.</Text>
          <Text>
            To add a new story, simply click the 'Add story' button. This opens
            a modal where you can add a story.
          </Text>
          <Image alt="" src={storiesdash} />
          <Text>Fill in the details and click submit to add your story.</Text>
          <Image alt="" src={addstorymodal} />
          <Subheader>Editing stories</Subheader>
          <Text>
            To edit stories that you've already created, open the story using
            the + icon in the top right hand corner of the story.
          </Text>
          <Text>
            Click the pen Icon in the bottom right hand corner. This opens a
            modal where you can edit and update your story.
          </Text>
          <Image alt="" src={editstories} />
          <Text>
            Update the story details and click update story to update.
          </Text>
          <Image alt="" src={editstory} />
        </Content>
      </Wrap>{' '}
      <Footer background="beige" />
    </>
  );
}
const Header = styled.div`
  background: #e97451;
  padding: 80px 20px 20px;
`;

const Wrap = styled.div`
  padding: 40px 60px 80px;
  background: white;

  @media (max-width: 767px) {
    padding: 30px 20px 60px;
  }
`;

const Content = styled.div`
  text-align: left;
  margin: auto;
  max-width: 65%;

  @media (max-width: 767px) {
    max-width: unset;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.h2`
  margin: 0 20px 50px;
  font-size: 1.25em;
`;

const Subheader = styled.h3`
  margin: 60px 0 30px;
  font-size: 1.5em;
  font-weight: 700;
`;

const Text = styled.p`
  line-height: 24px;
  margin-top: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin: 35px 0;
  border-radius: 10px;
`;
