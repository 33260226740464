import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function LimitMessage() {
  return (
    <LimitWrap>
      <Notice>You've exceeded your daily question limit.</Notice>
      <Notice>
        Come back tomorrow or <StyledLink to="/upgrade">upgrade</StyledLink>.
      </Notice>
    </LimitWrap>
  );
}

const Notice = styled.div`
  margin-top: 8px;
`;

const LimitWrap = styled.div`
  @media (max-width: 799px) {
    margin-top: 50px;
  }
`;

const StyledLink = styled(Link)``;
