import styled from 'styled-components';

import Modal from 'App/Components/Modal';
import DatePicker from 'App/Components/DatePicker';
import { Dayjs } from 'dayjs';
import { Button } from 'styles/buttons';
import { Story } from 'App/types/generalTypes';

type Props = {
  setOpen: (bool: boolean) => void;
  open: boolean;
  setDate: (date: Dayjs) => void;
  date: Dayjs;
  handleChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    type: string
  ) => void;
  submitStory: () => void;
  story: Story;
  disableSubmit: boolean;
  isEditing: boolean;
};

export default function AddModal({
  setOpen,
  open,
  date,
  setDate,
  handleChange,
  submitStory,
  story,
  disableSubmit,
  isEditing,
}: Props) {
  const buttonText = isEditing ? 'Update Story' : 'Submit';
  const title = isEditing ? 'Update your story' : 'Add story to your storyline';
  return (
    <Modal
      setOpen={setOpen}
      open={open}
      content={
        <div>
          <Title>{title}</Title>
          <Subtitle>
            Turn your memories into stories with meaning, humour or a clear
            message.
            {/* <button>Use this guide</button> */}
          </Subtitle>
          <InputWrap>
            <Label>Title</Label>
            <DefaultInput
              placeholder="Canada speech"
              required
              value={story.title}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<HTMLInputElement>,
                  'title'
                )
              }
            />
          </InputWrap>
          <InputWrap>
            <Label>Short description</Label>
            <DefaultInput
              placeholder="Best man speech on friend's wedding day."
              value={story.shortDescription}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<HTMLInputElement>,
                  'shortDescription'
                )
              }
            />
          </InputWrap>
          <InputWrap>
            <Label>Description</Label>
            <TextArea
              placeholder="A longer description of the story. Key highlights, not an essay."
              value={story.description}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<HTMLTextAreaElement>,
                  'description'
                )
              }
            />
          </InputWrap>
          <InputWrap>
            <Label>When did this happen</Label>
            <DateWrap>
              <DatePicker date={date} setDate={setDate} />{' '}
            </DateWrap>
          </InputWrap>
          <InputWrap>
            <Label>What's the message</Label>
            <DefaultInput
              placeholder="You have courage"
              value={story.message}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<HTMLInputElement>,
                  'message'
                )
              }
            />
          </InputWrap>
          <ButtonWrap>
            <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
            <SubmitButton disabled={disableSubmit} onClick={submitStory}>
              {buttonText}
            </SubmitButton>
          </ButtonWrap>
        </div>
      }
    />
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Subtitle = styled.div`
  color: grey;
  margin-top: 8px;
  margin-bottom: 30px;
  font-size: 0.875em;
  line-height: 20px;
`;

const DefaultInput = styled.input`
  border: 1px solid rgb(209 213 219);
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  box-sizing: border-box;
  height: 2.35rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
`;

const Label = styled.label`
  font-size: 0.875em;
  margin-bottom: 0.5rem;
  display: block;
  font-weight: 600;
  color: #4c4e52;
`;

const InputWrap = styled.div`
  margin-top: 22px;
`;

const TextArea = styled.textarea`
  border: 1px solid rgb(209 213 219);
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.875rem;
  resize: vertical;
`;

const DateWrap = styled.div`
  margin-top: 14px;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  color: white;
  background: #e97451;

  ${({ disabled }) => disabled && 'background:grey'};
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
  background: transparent;
  border: 1px solid black;
  color: black;
`;
