import styled from 'styled-components';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom';

import mainlogo from '../../styles/mainlogo.png';

type Props = {
  background?: string;
};

interface WrapProps {
  background: string;
}

export default function Footer({ background = 'white' }: Props) {
  return (
    <Wrap background={background}>
      <Section>
        <Logo>
          <Image src={mainlogo} alt="" />
        </Logo>
      </Section>
      <Section>
        <Header>Soapboxer for</Header>
        <StyledLink to="/toastmasters">Toastmasters</StyledLink>
        <div>Introverts</div>
        <div>Professionals</div>
        <div>Podcasters</div>
      </Section>
      <Section>
        <Header>Legal</Header>
        <StyledLink to="/terms">Terms</StyledLink>
        <div>
          <StyledLink to="/privacy">Privacy</StyledLink>
        </div>
      </Section>
      <Section>
        <Icon>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/soapboxerco"
          >
            <TwitterIcon />
          </a>
        </Icon>
        <div>&copy; 2022 soapboxer.co</div>
      </Section>
    </Wrap>
  );
}

const Wrap = styled.div<WrapProps>`
  font-size: 0.875em;
  line-height: 35px;
  bottom: 0;
  padding: 60px 60px;
  background: ${({ background }) => background};
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 575px) {
    flex-direction: column;
    padding: 40px 20px 20px;
  }
`;

const Icon = styled.div`
  margin-top: -4px;

  @media (max-width: 575px) {
    margin-top: 50px;
  }

  svg {
    height: 0.75em;
    width: 0.75em;
    color: black;
  }
`;

const Header = styled.div`
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 700;

  @media (max-width: 575px) {
    margin-top: 20px;
  }
`;

const Image = styled.img`
  width: 160px;
`;
const Logo = styled.div`
  margin-bottom: 20px;

  @media (max-width: 575px) {
    margin-bottom: 5px;
  }
`;

const Section = styled.div`
  text-align: left;
  @media (max-width: 575px) {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  :hover {
    cursor: pointer;
  }
`;
