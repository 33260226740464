import { useState, useCallback, useEffect, useContext } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useUser } from '@clerk/clerk-react';
import HomeComponent from 'App/Home/HomeComponent';
import { Tip, AnswerLimit, Stat, Question } from 'App/types/generalTypes';
import useRequest from 'App/hooks/useRequest';
import { AppContext } from 'AppRoutes';

function HomeContainer() {
  const [step, setStep] = useState<string>('first');
  const [message, setMessage] = useState<string>('');
  const [stats, setStats] = useState<Stat>({ total: 0, totalTime: '' });
  const [currentQuestion, setCurrentQuestion] = useState<Question>({
    id: 0,
    question: '',
    category: '',
    difficulty: 0,
    type: '',
  });
  const [tip, setTip] = useState<Tip>({ id: 0, tip: '', type: '' });
  const [answerLimit, setAnswerLimit] = useState<AnswerLimit>({
    exceeded: false,
    answers: 0,
    limit: 0,
  });
  const [categories, setCategories] = useState<string[]>([]);

  const { getStats, getRandomTip, getAnswerLimit, getQuestion, postAnswer } =
    useRequest();

  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const timeOver = minutes >= 2 && seconds >= 30;
  const today = new Date();

  const { user } = useUser();
  const userId = user?.id || '';
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  // TODO - setup home context
  const resetQuestion = useCallback(
    (message: string) => {
      async function sendAnswer() {
        const totalTime = minutes * 60 + seconds;
        const answer = {
          userId,
          questionId: currentQuestion.id,
          time: totalTime,
          dateAdded: today,
        };
        if (currentQuestion.question) {
          await postAnswer(answer);
        }
      }

      reset();
      pause();
      setStep('fourth');
      sendAnswer();

      // set different messages based on time
      if (message) {
        setMessage(message);
        return;
      }

      if (minutes === 0) {
        setMessage('Great work, try and reach a minute next time.');
        return;
      }

      setMessage('Great work, have another go.');
    },
    [minutes, pause, setStep, seconds, userId, currentQuestion]
  );

  useEffect(() => {
    async function fetchStats() {
      const stats = await getStats(userId);
      setStats(stats.data);
    }
    fetchStats();
  }, [userId, currentQuestion, step]);

  useEffect(() => {
    async function fetchTip() {
      const tip = await getRandomTip();
      setTip(tip.data[0]);
    }
    fetchTip();
  }, []);

  // if user is subscribed, don't need to do this
  useEffect(() => {
    async function fetchAnswerLimit() {
      const limit = await getAnswerLimit(userId);
      !isActive && setAnswerLimit(limit.data);
    }
    fetchAnswerLimit();
  }, [step, currentQuestion]);

  useEffect(
    function maxTimeExceeded() {
      if (timeOver) {
        resetQuestion('Ahhh heck, you went over the max time. Try again.');
      }
    },
    [timeOver, resetQuestion]
  );

  // if hit this, need to count this as one of their limit
  // rather than when click finish as could game the system
  async function handleQuestion() {
    setMessage('');
    reset();

    const question = await getQuestion(userId);
    if (question.data) {
      setCurrentQuestion(question.data);
      setStep('third');
    } else {
      pause();
      setMessage("Sorry we've got no questions");
    }
  }

  function setupQuestion() {
    setStep('second');
    setMessage('Step up to the soapbox...');
  }

  function restart() {
    reset();
    pause();
    setStep('first');
    setMessage('');
  }

  console.log('SELECTED CATEGORies', categories);

  function selectCategory(category: string) {
    // find if exists
    const exists = categories.find((cat) => cat === category);
    if (exists) {
      // remove from array
      const filtered = categories.filter((cat) => cat !== category);
      setCategories(filtered);
      return;
    }
    // else add to array
    setCategories([...categories, category]);
  }

  return (
    <HomeComponent
      seconds={seconds}
      minutes={minutes}
      step={step}
      message={message}
      resetQuestion={resetQuestion}
      handleQuestion={handleQuestion}
      currentQuestion={currentQuestion}
      setupQuestion={setupQuestion}
      restart={restart}
      stats={stats}
      tip={tip}
      answerLimit={answerLimit}
      selectCategory={selectCategory}
      categories={categories}
    />
  );
}

export default HomeContainer;
