export const book = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.25 -0.25 14.5 14.5"
    height="16"
    width="16"
    strokeWidth="1.25"
  >
    <g>
      <path
        d="M12.5,13.54H3a1.5,1.5,0,0,1,0-3h8.5a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1H3A1.5,1.5,0,0,0,1.5,2V12"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <line
        x1="11.5"
        y1="10.54"
        x2="11.5"
        y2="13.54"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
    </g>
  </svg>
);
