import styled from 'styled-components';
import { type Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';

import { Button } from 'styles/buttons';
import AddModal from 'App/Story/AddModal';
import DeleteModal from 'App/Story/DeleteModal';
import { Story } from 'App/types/generalTypes';
import StoryCard from 'App/Story/StoryCard';
import { isDesktop } from 'App/helpers/screenSize';

type Props = {
  setDate: (date: Dayjs) => void;
  date: Dayjs;
  handleChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    type: string
  ) => void;
  submitStory: () => void;
  story: Story;
  setOpen: (bool: boolean) => void;
  open: boolean;
  disableSubmit: boolean;
  stories: Story[];
  deleteStory: () => void;
  setOpenStory: (bool: number | undefined) => void;
  openStory: number | undefined;
  setDeleteModalOpen: (bool: boolean) => void;
  deleteModalOpen: boolean;
  editStory: (story: Story) => void;
  isEditing: boolean;
  openAddModal: () => void;
  enabled: boolean | undefined;
};

export default function StoryComponent({
  date,
  setDate,
  handleChange,
  submitStory,
  story,
  open,
  setOpen,
  disableSubmit,
  stories,
  deleteStory,
  openStory,
  setOpenStory,
  deleteModalOpen,
  setDeleteModalOpen,
  editStory,
  isEditing,
  openAddModal,
  enabled,
}: Props) {
  function renderStories() {
    if (stories.length === 0) {
      return (
        <Card>
          <Badge>Example</Badge>
          <Details>
            {' '}
            <StoryTitle>France trip</StoryTitle>
            <ShortDescription>
              We had an amazing time in France. The bakeries, the small
              villages...
            </ShortDescription>
            <CardWrap>
              <CardDate>02/05/2005</CardDate>
            </CardWrap>
          </Details>
        </Card>
      );
    }

    return stories.map((story) => {
      return (
        <StoryCard
          key={story.id}
          story={story}
          openStory={openStory}
          setOpenStory={setOpenStory}
          setDeleteModalOpen={setDeleteModalOpen}
          editStory={editStory}
        />
      );
    });
  }

  return (
    <>
      <Wrap>
        <Header>
          <div>
            <Title>Stories</Title>
            <Subtitle>Turn your memories into stories.</Subtitle>
          </div>
          {enabled && (
            <ButtonWrap>
              <StyledButton onClick={() => openAddModal()}>
                + {isDesktop && <>Add story</>}
              </StyledButton>
            </ButtonWrap>
          )}
        </Header>
        <StoriesWrap>
          {renderStories()}{' '}
          {!enabled && (
            <DisabledButtonWrap>
              <Link to="/upgrade">
                <StyledButton>Upgrade to add more</StyledButton>
              </Link>
            </DisabledButtonWrap>
          )}
        </StoriesWrap>
      </Wrap>
      <AddModal
        setOpen={setOpen}
        open={open}
        date={date}
        setDate={setDate}
        handleChange={handleChange}
        submitStory={submitStory}
        story={story}
        disableSubmit={disableSubmit}
        isEditing={isEditing}
      />
      <DeleteModal
        setOpen={setDeleteModalOpen}
        open={deleteModalOpen}
        deleteStory={deleteStory}
      />
    </>
  );
}

const Wrap = styled.div`
  text-align: left;

  @media (min-width: 800px) {
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 799px) {
    background: #e97451;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 0 20px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

const Subtitle = styled.div`
  color: grey;
  margin-top: 8px;
  font-size: 0.875em;

  @media (max-width: 799px) {
    color: white;
  }
`;

const ButtonWrap = styled.div``;

const DisabledButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const StyledButton = styled(Button)`
  color: white;
  @media (max-width: 799px) {
    color: black;
  }
`;

const StoriesWrap = styled.div`
  margin-top: 60px;

  @media (max-width: 800px) {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 40px 20px;
    margin: unset;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 16px 24px;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
  background: #f2f6f8;
`;

const StoryTitle = styled.div`
  font-weight: 700;
  font-size: 0.875em;
`;

const ShortDescription = styled.div`
  color: grey;
  font-size: 0.875em;
  margin-top: 4px;
`;

const CardWrap = styled.div`
  display: flex;
`;

const CardDate = styled.div`
  background: lightgrey;
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 4px;
  margin-top: 10px;
`;

const Details = styled.div``;

const Badge = styled.div`
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  transform: rotate(-9deg);
  padding: 6px 9px;
  background: black;
  border-radius: 4px;
  color: white;
  top: -15px;
  left: 17px;
  letter-spacing: 1px;
`;
