import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useContext, ReactElement } from 'react';

import { UserButton, useUser } from '@clerk/clerk-react';
import mainlogo from '../../styles/mainlogo.png';
import { isDesktop } from 'App/helpers/screenSize';
import { speaker } from '../../styles/icons/speaker.js';
import { book } from '../../styles/icons/book.js';
import { love } from '../../styles/icons/love.js';
import { arrows } from '../../styles/icons/arrows.js';
import { write } from '../../styles/icons/write.js';
import { wrench } from '../../styles/icons/wrench.js';
import { AppContext } from 'AppRoutes';
import { settings } from '../../styles/icons/settings';

type Props = {
  children: ReactElement;
};

export default function DesktopNav({ children }: Props) {
  const user = useUser();
  const name = user?.user?.fullName;
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  if (!isDesktop) {
    return null;
  }
  return (
    <>
      {' '}
      <Container>
        <SideBar>
          <Link to="/soap">
            <Logo>
              <Image src={mainlogo} alt="" />
            </Logo>
          </Link>
          <NavWrap>
            <NavItem to="/soap">
              <IconWrap>{speaker}</IconWrap>
              <span>The soapbox</span>
            </NavItem>
            <NavItem to="/stories">
              <IconWrap>{book}</IconWrap>
              <span>Stories</span>
            </NavItem>
            <NavTitle>Help</NavTitle>
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://soapboxer.canny.io/feedback"
            >
              <IconWrap>{write}</IconWrap>
              <span>Send feedback</span>
            </ExternalLink>
            {/* <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://tally.so/r/nWOv2a"
            >
              <IconWrap>{love}</IconWrap>
              <span>Share testimonial</span>
            </ExternalLink> */}
            {!isActive && (
              <NavItem to="/upgrade">
                <IconWrap>{arrows}</IconWrap>
                <span>Upgrade</span>
              </NavItem>
            )}
            <NavItem to="/guide">
              <IconWrap>{wrench}</IconWrap>
              <span>Guide</span>
            </NavItem>
            {isActive && (
              <NavItem to="/account">
                <IconWrap>{settings}</IconWrap>
                <span>Account</span>
              </NavItem>
            )}
            <UserWrap>
              <UserButton afterSignOutUrl="/" />
              <UserName>{name}</UserName>
            </UserWrap>
          </NavWrap>
        </SideBar>
        <Content>
          <div>{children}</div>
        </Content>
      </Container>
    </>
  );
}
const Container = styled.div`
  position: relative;
`;

const SideBar = styled.div`
  position: fixed;
  background-color: #e97451;
  height: 100vh;
  width: 180px;
  z-index: 10;
  text-align: left;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const NavTitle = styled.div`
  margin-top: 30px;
`;

const NavWrap = styled.div``;

const NavItem = styled(Link)`
  margin-top: 16px;
  display: flex;
  font-size: 0.875em;
  font-weight: 700;

  text-decoration: none;
  color: black;
  display: flex;

  :hover {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  > span {
    margin-left: 10px;
    line-height: 24px;
  }
`;

const ExternalLink = styled.a`
  margin-top: 16px;
  display: flex;
  font-size: 0.875em;
  font-weight: 700;

  text-decoration: none;
  color: black;
  display: flex;

  :hover {
    text-decoration: none;
    color: black;
  }

  > span {
    margin-left: 10px;
    line-height: 24px;
  }
`;

const Content = styled.div`
  padding: 20px 40px 40px 260px;
`;

const UserWrap = styled.div`
  position: absolute;
  bottom: 25px;
  display: flex;
`;

const UserName = styled.div`
  margin: 8px 0 0 10px;
  font-size: 0.875em;
`;

const Logo = styled.div`
  margin-bottom: 45px;
`;

const Image = styled.img`
  width: 160px;
`;

const IconWrap = styled.div`
  margin-top: 4px;
`;
