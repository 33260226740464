import styled from 'styled-components';
import { useEffect } from 'react';
import Footer from 'App/LandingPage/Footer';

export default function Terms() {
  useEffect(function scrollToTop() {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Wrap>
        <Title>Terms of service</Title>
        <Content>
          <Subtitle>Intro</Subtitle>
          <Text>Welcome to Soapboxer (“Company”, “we”, “our”, “us”).</Text>
          <Text>
            Your agreement with us includes these Terms. You acknowledge that
            you have read and understood Agreements, and agree to be bound of
            them.{' '}
          </Text>
          <Text></Text>
          <Text>
            If you do not agree with (or cannot comply with) with these Terms,
            then you may not use the Service. These Terms apply to all visitors,
            users and others who wish to access or use the Service.
          </Text>
          <Subtitle>Subscriptions</Subtitle>
          <Text>
            Some parts of Service are billed on a subscription basis
            (“Subscription(s)”). You will be billed in advance on a recurring
            and periodic basis (“Billing Cycle”). Billing cycles are set on a
            monthly or yearly basis.
          </Text>
          <Text>
            At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you
            cancel it or Soapboxer cancels it. You may cancel your Subscription
            renewal either through your online account management page or by
            contacting Soapboxer.
          </Text>
          <Text>
            A valid payment method, including credit card, is required to
            process the payment for your subscription. (via Paddle).
          </Text>
          <Text>
            Soapboxer's service may change without prior notice. Changes could
            affect pricing, pricing policies, features and access restrictions.
          </Text>{' '}
          <Subtitle>Free Trial</Subtitle>
          <Text>
            Soapboxer may, at its sole discretion, offer a Subscription with a
            free trial for a limited period of time (“Free Trial”).
          </Text>
          <Text>
            You may be required to enter your billing information in order to
            sign up for Free Trial.
          </Text>
          <Text>
            If you do enter your billing information when signing up for Free
            Trial, you will not be charged by Soapboxer (via Paddle). until Free
            Trial has expired. On the last day of Free Trial period, unless you
            cancelled your Subscription, you will be automatically charged the
            applicable Subscription fees for the type of Subscription you have
            selected.
          </Text>
          <Text>
            At any time and without notice, Soapboxer reserves the right to (i)
            modify Terms of Service of Free Trial offer, or (ii) cancel such
            Free Trial offer.
          </Text>
          <Subtitle>Refunds</Subtitle>
          <Text>Except when required by law, refunds are not available.</Text>
          <Subtitle>Contact us</Subtitle>
          <Text>
            Paul Rayner
            <br />
            paul@soapboxer.co{' '}
          </Text>
        </Content>
      </Wrap>
      <Footer background="beige" />
    </>
  );
}

const Wrap = styled.div`
  padding: 80px 60px;
  background: white;

  @media (max-width: 767px) {
    padding: 60px 20px;
  }
`;

const Content = styled.div`
  text-align: left;
  margin: auto;
  max-width: 65%;

  @media (max-width: 767px) {
    max-width: unset;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  margin-top: 50px;
  font-size: 2em;
  font-weight: 700;
`;

const Text = styled.p`
  line-height: 24px;
  margin-top: 20px;
`;
