import styled from 'styled-components';
import { useContext } from 'react';

import Stats from 'App/Home/Stats';
import { Stat, Tip, AnswerLimit, Question } from 'App/types/generalTypes';
import { isDesktop } from 'App/helpers/screenSize';
import { getColour } from 'App/helpers/colour';
import { AppContext } from 'AppRoutes';
import Buttons from 'App/Home/Buttons';
import DesktopHome from 'App/Home/DesktopHome';
import LimitMessage from 'App/Home/LimitMessage';

type Props = {
  minutes: number;
  seconds: number;
  step: string;
  message: string;
  resetQuestion: (message: string) => void;
  handleQuestion: () => void;
  currentQuestion: Question;
  setupQuestion: () => void;
  restart: () => void;
  stats: Stat | undefined;
  tip: Tip;
  answerLimit: AnswerLimit;
  selectCategory: (category: string) => void;
  categories: string[];
};

interface StopwatchProps {
  colour: string;
}

function HomeComponent({
  minutes,
  seconds,
  step,
  message,
  resetQuestion,
  handleQuestion,
  currentQuestion,
  setupQuestion,
  restart,
  stats,
  tip,
  answerLimit,
  selectCategory,
  categories,
}: Props) {
  const showTimer = step !== 'first';
  const showQuestion = step === 'third' || step === 'fourth';
  const showRestart = step === 'fourth';
  const { userInfo } = useContext(AppContext) || {};
  const { isActive } = userInfo || {};

  if (isDesktop) {
    return (
      <DesktopHome
        answerLimit={answerLimit}
        step={step}
        stats={stats}
        handleQuestion={handleQuestion}
        resetQuestion={resetQuestion}
        setupQuestion={setupQuestion}
        showQuestion={showQuestion}
        currentQuestion={currentQuestion}
        message={message}
        showRestart={showRestart}
        restart={restart}
        minutes={minutes}
        seconds={seconds}
        showTimer={showTimer}
        tip={tip}
        selectCategory={selectCategory}
        categories={categories}
      />
    );
  }

  return (
    <>
      <Wrap>
        <Buttons
          answerLimit={answerLimit}
          step={step}
          handleQuestion={handleQuestion}
          resetQuestion={resetQuestion}
          setupQuestion={setupQuestion}
        />
      </Wrap>
      <ContentWrap>
        <Stats step={step} stats={stats} answerLimit={answerLimit} />
        {!answerLimit.exceeded || isActive ? (
          <>
            {showQuestion && (
              <QuestionWrap>{currentQuestion.question}</QuestionWrap>
            )}
            {message && <Message>{message}</Message>}
            {showRestart && <Restart onClick={restart}>Restart</Restart>}
            {showTimer && (
              <Stopwatch colour={getColour(minutes)}>
                {minutes}:{seconds}
              </Stopwatch>
            )}
          </>
        ) : (
          <LimitMessage />
        )}
      </ContentWrap>
      {showTimer && !answerLimit.exceeded && (
        <TipWrap>
          <TipText>{tip?.tip}</TipText>
          <TipTitle>Coach</TipTitle>
        </TipWrap>
      )}
    </>
  );
}

export default HomeComponent;

export const Wrap = styled.div`
  padding: 80px 20px 40px;
  background: #e97451;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
`;

export const ContentWrap = styled.div`
  margin-top: 80px;
  padding: 40px 20px;
  height: 70vh;

  @media (min-width: 800px) {
    border-radius: 5px;
    background: #f2f6f8;
    height: unset;
    display: flex;
    justify-content: center;
  }
`;

export const InnerWrap = styled.div`
  @media (min-width: 800px) {
    max-width: 600px;
  }
`;

export const TipWrap = styled.div`
  position: fixed;
  bottom: 20px;
  background: #e97451;
  border-radius: 5px;
  padding: 12px;
  margin: 0 20px;
  width: 83%;

  @media (min-width: 800px) {
    background: #e97451;
    border-radius: 5px;
    padding: 12px;
    margin-top: 100px;
    min-width: 350px;
    min-height: 80px;
    position: relative;
    bottom: unset;
    width: unset;
  }
`;

export const TipTitle = styled.div`
  font-size: 0.75em;
  font-weight: 700;
  position: absolute;
  bottom: 5px;
`;

export const TipText = styled.div`
  margin-bottom: 20px;
  color: white;
`;

export const QuestionWrap = styled.div`
  font-weight: 700;
  font-size: 1.25em;
  letter-spacing: 1px;

  @media (min-width: 800px) {
    font-size: 1.75em;
    margin-top: 60px;
  }
`;

export const Stopwatch = styled.div<StopwatchProps>`
  font-weight: 700;
  font-size: 2em;
  text-align: center;
  margin-top: 50px;

  ${({ colour }) => `color: ${colour};`};
`;

export const Message = styled.div`
  margin-top: 30px;
  color: grey;

  @media (min-width: 800px) {
    text-align: center;
    margin-top: 20px;
  }
`;

export const Restart = styled.div`
  margin-top: 8px;
  font-size: 0.875em;
  color: grey;

  :hover {
    cursor: pointer;
  }
`;
