import styled from 'styled-components';
import { Link } from 'react-router-dom';

import circles from 'styles/svgs/circles.svg';
import blob from 'styles/svgs/blob.svg';
import swoops from 'styles/svgs/swoops.svg';

function HeroFooter() {
  return (
    <Wrap>
      {' '}
      <InnerWrap>
        <CirclesWrap>
          <Circles src={circles} alt="coloured circles" />
        </CirclesWrap>
        <BlobWrap>
          <Blob src={blob} alt="coloured Blob" />
        </BlobWrap>
        <SwoopsWrap>
          <Swoops src={swoops} alt="" />
        </SwoopsWrap>
        <div>
          <BigSubtitle>Wow the crowd</BigSubtitle>
          <Text>Public speaking is tough...</Text>
          <Text>But it doesn't have to be.</Text>
          <Text>You CAN get better.</Text>
          <Text>You CAN get more confident.</Text>
        </div>
        <StyledLink to="/sign-up">
          <RoundButton>Get started for free</RoundButton>
          <SmallText>No credit card needed</SmallText>
        </StyledLink>
      </InnerWrap>
    </Wrap>
  );
}

export default HeroFooter;

const Wrap = styled.div`
  padding: 50px 0px;
  position: relative;
  background: #e97451;

  @media (max-width: 767px) {
    padding: 100px 20px;
  }
`;

const InnerWrap = styled.div`
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const BigSubtitle = styled.div`
  font-weight: 700;
  font-size: 2.25em;
  margin-bottom: 30px;
  text-align: left;
`;

const Text = styled.div`
  margin-bottom: 10px;
  text-align: left;
`;

const SmallText = styled.div`
  margin-top: 5px;
  font-size: 0.75em;
`;

const Circles = styled.img`
  height: 70px;

  @media (min-width: 768px) {
    height: 120px;
  }
`;

const CirclesWrap = styled.div`
  position: absolute;
  top: 6px;
  left: -3%;
`;

const Blob = styled.img`
  height: 70px;

  @media (min-width: 768px) {
    height: 120px;
  }
`;

const BlobWrap = styled.div`
  position: absolute;
  top: -15px;
  right: 3%;
`;

const Swoops = styled.img`
  height: 70px;

  @media (min-width: 768px) {
    height: 120px;
  }
`;

const SwoopsWrap = styled.div`
  position: absolute;
  top: 6px;
  right: 3%;
`;

const RoundButton = styled.button`
  border-radius: 30px;
  background: black;
  color: white;
  padding: 12px 20px;
  border: none;
  font-weight: 700;
  font-size: 1em;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  margin: auto;
  :hover {
    text-decoration: none;
    color: black;
  }
`;
