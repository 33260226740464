import { ReactElement } from 'react';
import { useUser } from '@clerk/clerk-react';
import { isDesktop } from 'App/helpers/screenSize';
import AppNav from 'App/Components/AppNav';
import DesktopNav from 'App/Components/DesktopNav';

type Props = {
  Component: ReactElement;
};

export default function SignedInComponent({ Component }: Props) {
  const user = useUser();
  const { isSignedIn } = user;

  if (!isSignedIn) {
    return null;
  }

  return (
    <>
      {isDesktop ? (
        <>
          <DesktopNav>{Component}</DesktopNav>
        </>
      ) : (
        <>
          {' '}
          <AppNav />
          {Component}
        </>
      )}
    </>
  );
}
