import styled from 'styled-components';

import LandingPageComponent from 'App/LandingPage/LandingPageComponent';

export default function LandingPage() {
  const title = (
    <>
      Become a <Highlight>confident</Highlight> public speaker
    </>
  );

  return (
    <LandingPageComponent
      title={title}
      subtitle="Tools to help you master off the cuff speaking."
    />
  );
}

const Highlight = styled.span`
  color: white;
`;
