import { Routes, Route } from 'react-router-dom';
import { useState, createContext } from 'react';

import './App.css';
import LandingPage from 'App/LandingPage/LandingPage';
import ToastmastersPage from 'App/LandingPage/ToastmastersPage';
import HomeContainer from 'App/Home/HomeContainer';
import AdminContainer from 'App/Admin/AdminContainer';
import TipsContainer from 'App/Admin/Tips/TipsContainer';
import SignInPage from 'App/Components/SignInPage';
import SignUpPage from 'App/Components/SignUpPage';
import { useUser } from '@clerk/clerk-react';
import UpgradePage from 'App/Components/UpgradePage';
import StoryContainer from 'App/Story/StoryContainer';
import Terms from 'App/LandingPage/Terms';
import Privacy from 'App/LandingPage/Privacy';
import GuidePage from 'App/Guide/GuidePage';
import Account from 'App/Account/Account';
import useFetchUser from 'App/hooks/useFetchUser';
import useRequest from 'App/hooks/useRequest';
import dayjs from 'dayjs';
import SignedInComponent from 'App/Components/SignedInComponent';
import SignedOutComponent from 'App/Components/SignedOutComponent';

interface UserInfo {
  updateUrl: string;
  cancelUrl: string;
  isActive: boolean;
  status: string;
}

const defaultUserInfo = {
  updateUrl: '',
  cancelUrl: '',
  isActive: false,
  status: '',
};

type UserContext = {
  userInfo: UserInfo;
  getUserInfo: () => void;
};

export const AppContext = createContext<UserContext | null>(null);

export default function AppRoutes() {
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const user = useUser();
  const { isSignedIn } = user;
  const isAdmin = user?.user?.publicMetadata?.admin;
  const userId = user?.user?.id;

  const { getUser } = useRequest();
  const today = dayjs();

  useFetchUser(setUserInfo);

  // after payment we call this to get data again
  // TODO - upgrade to redux
  const getUserInfo = async () => {
    if (userId) {
      const status = await getUser(userId);

      const isWithinDate =
        status.data.endDate && today.isBefore(dayjs(status.data.endDate));

      if (
        status.data.status === 'active' ||
        status.data.status === 'trialing' ||
        isWithinDate ||
        isAdmin
      ) {
        console.log('HERE');
        setUserInfo({
          isActive: true,
          updateUrl: status.data.updateUrl,
          cancelUrl: status.data.cancelUrl,
          status: status.data.status,
        });
      } else {
        setUserInfo({ ...defaultUserInfo, status: status.data.status });
      }
    }
  };

  return (
    <AppContext.Provider value={{ userInfo, getUserInfo }}>
      <Routes>
        <Route
          path="/sign-in"
          element={<SignedOutComponent Component={<SignInPage />} />}
        />
        <Route
          path="/sign-in/factor-one"
          element={<SignedOutComponent Component={<SignInPage />} />}
        />
        <Route
          path="/sign-up"
          element={<SignedOutComponent Component={<SignUpPage />} />}
        />
        <Route
          path="/sign-up/verify-email-address"
          element={<SignedOutComponent Component={<SignUpPage />} />}
        />
        <Route
          index
          element={<SignedOutComponent Component={<LandingPage />} />}
        />
        <Route
          path="/toastmasters"
          element={<SignedOutComponent Component={<ToastmastersPage />} />}
        />
        <Route
          path="/guide"
          element={<SignedOutComponent Component={<GuidePage />} />}
        />
        <Route
          path="/terms"
          element={<SignedOutComponent Component={<Terms />} />}
        />
        <Route
          path="/privacy"
          element={<SignedOutComponent Component={<Privacy />} />}
        />
        <>
          <Route
            path="/soap"
            element={<SignedInComponent Component={<HomeContainer />} />}
          />
          <Route
            path="/stories"
            element={<SignedInComponent Component={<StoryContainer />} />}
          />
          <Route
            path="/account"
            element={<SignedInComponent Component={<Account />} />}
          />
          <Route
            path="/upgrade"
            element={<SignedInComponent Component={<UpgradePage />} />}
          />
          {isAdmin && isSignedIn && (
            <>
              <Route path="/admin/questions" element={<AdminContainer />} />
              <Route path="/admin/tips" element={<TipsContainer />} />{' '}
            </>
          )}
        </>
      </Routes>
    </AppContext.Provider>
  );
}
