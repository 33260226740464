import { useState, useEffect, useCallback } from 'react';

import TipsComponent from 'App/Admin/Tips/TipsComponent';
import { Option, Tip } from 'App/Admin/options';
import { parse, ParseResult } from 'papaparse';
import useRequest from 'App/hooks/useRequest';

function TipsContainer() {
  const defaultValues = {
    tip: '',
    type: '',
  };
  const [values, setValues] = useState<Tip>(defaultValues);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [tips, setTips] = useState<Tip[]>([]);
  const [file, setFile] = useState<File>();
  const { getTips, postTip, postTipCsv } = useRequest();

  const fetchTips = useCallback(async () => {
    const fetchedTips = await getTips();
    const { data } = fetchedTips;
    if (data && data.length > 0) {
      setTips(data);
    }
  }, []);

  useEffect(() => {
    fetchTips();
  }, [fetchTips, values, file]);

  function handleChange(option: Option, select: string) {
    setValues({ ...values, [select]: option.value });
  }

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    setValues({ ...values, tip: event.target.value });
  }

  function handleChangeUpload(event: React.ChangeEvent<HTMLInputElement>) {
    setFile(event.target.files?.[0]);
  }

  const submit = async () => {
    const postedTip = await postTip(values);
    const { status, data } = postedTip;

    if (status === 201) {
      setValues(defaultValues);
      setSnackBarOpen(true);
      setTips([...tips, data]);
    }
  };

  async function submitCsv() {
    console.log(file);
    // const formData = new FormData();

    if (file) {
      parse(file, {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async function (
          results: ParseResult<Record<string, unknown>>
        ) {
          const result = await postTipCsv(results.data);
          const { status } = result;

          if (status === 201) {
            setSnackBarOpen(true);
            setFile(undefined);
          }
        },
      });
    }
  }

  return (
    <TipsComponent
      handleChange={handleChange}
      handleInput={handleInput}
      values={values}
      submit={submit}
      snackBarOpen={snackBarOpen}
      setSnackBarOpen={setSnackBarOpen}
      tips={tips}
      handleChangeUpload={handleChangeUpload}
      submitCsv={submitCsv}
      file={file}
    />
  );
}

export default TipsContainer;
