import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import mainlogo from '../../styles/mainlogo.png';

export default function Nav() {
  const user = useUser();
  const { isSignedIn } = user;

  return (
    <div>
      <Header>
        <Link to="/">
          <Logo>
            <Image src={mainlogo} alt="" />
          </Logo>
        </Link>
        {isSignedIn ? (
          <MenuWrap>
            <StyledLink to="/soap">
              <RoundButton>GO TO APP</RoundButton>
            </StyledLink>
          </MenuWrap>
        ) : (
          <Buttons>
            <StyledLink to="/sign-in">
              <RoundButton>Sign in</RoundButton>
            </StyledLink>
          </Buttons>
        )}
      </Header>
    </div>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: -1px;
  background: #e97451;
`;

const RoundButton = styled.button`
  border-radius: 30px;
  background: black;
  color: white;
  padding: 0 16px 0;
  border: none;
  font-weight: 700;
  font-family: 'Satoshi', sans-serif;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Logo = styled.div`
  padding-top: 4px;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

const Image = styled.img`
  width: 225px;

  @media (max-width: 767px) {
    width: 175px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;

  :hover {
    text-decoration: none;
    color: black;
  }
`;

const MenuWrap = styled.div`
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
`;
