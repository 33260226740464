import styled from 'styled-components';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import strike from 'styles/svgs/strike.svg';
import loopy from 'styles/svgs/loopy.svg';

function MembershipBenefits() {
  return (
    <Section>
      <StrikeWrap>
        <Strike src={strike} alt="lightening strike" />
      </StrikeWrap>
      <LoopyWrap>
        <Loopy src={loopy} alt="Loopy line" />
      </LoopyWrap>
      <Header>
        <BigSubtitle>Membership benefits</BigSubtitle>
        <Subtitle>Everything you need to become a confident speaker</Subtitle>
      </Header>
      <Row>
        <Col>
          <Icon>
            <CampaignOutlinedIcon />
          </Icon>
          <BlockTitle>Structure</BlockTitle>
          <BlockText>
            We provide the structure you need to become a better speaker.
          </BlockText>
        </Col>
        <Col>
          <Icon>
            <QuestionAnswerOutlinedIcon />
          </Icon>
          <BlockTitle>100's of questions</BlockTitle>
          <BlockText>
            100's of varied qustions to practise your responses. No need to
            search yourself.
          </BlockText>
        </Col>
        <Col>
          <Icon>
            <TimelineOutlinedIcon />
          </Icon>
          <BlockTitle>Track your progress</BlockTitle>
          <BlockText>
            See how you're progressing and improving with stats.
          </BlockText>
        </Col>
        <Col>
          <Icon>
            <LightbulbOutlinedIcon />
          </Icon>
          <BlockTitle>Coaching tips</BlockTitle>
          <BlockText>
            Coaching tips to help you answer off the cuff questions more
            effectively.
          </BlockText>
        </Col>
        <Col>
          <Icon>
            <RocketLaunchOutlinedIcon />
          </Icon>
          <BlockTitle>Stories</BlockTitle>
          <BlockText>
            Turn your memories into stories so you can use them for public
            speaking.
          </BlockText>
        </Col>
        <Col>
          <Icon>
            <AutoAwesomeOutlinedIcon />
          </Icon>
          <BlockTitle>More more more</BlockTitle>
          <BlockText>We've got big plans for future ideas...</BlockText>
        </Col>
      </Row>
    </Section>
  );
}
export default MembershipBenefits;

const Section = styled.div`
  background: white;
  padding: 80px 60px 100px;
  position: relative;
  border-radius: 0 0 100px 100px;

  @media (max-width: 767px) {
    padding: 60px 20px;
  }
`;

const Header = styled.div`
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 500px;
`;

const Subtitle = styled.div`
  font-size: 1.5em;
  padding-bottom: 60px;
`;

const BigSubtitle = styled.div`
  font-weight: 700;
  font-size: 2.5em;
  padding-bottom: 40px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 60px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const Col = styled.div`
  text-align: center;
`;

const BlockTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.6px;
  margin-bottom: 16px;
`;

const BlockText = styled.div`
  font-size: 16px;
`;

const Icon = styled.div`
  margin-bottom: 15px;

  > svg {
    height: 2em;
    width: 2em;
  }
`;

const Strike = styled.img`
  height: 80px;

  @media (min-width: 768px) {
    height: 100px;
  }
`;

const StrikeWrap = styled.div`
  position: absolute;
  right: 3vw;
  top: -23px;
`;

const Loopy = styled.img`
  height: 60px;
`;

const LoopyWrap = styled.div`
  position: absolute;
  top: -32px;
  left: 45%;
`;
